import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 用户评价 - 分页查询
 * @param params
 */
export function getEvalutionList(params) {
  return request({
    url: envInfo.bgApp.gradePath + '/manage/queryGradeList',
    method: 'get',
    params,
  });
}

/**
 * 用户评价 - 导出
 * @param params
 */
export function evalutionListExport(params) {
  return request({
    url: envInfo.bgApp.gradePath + '/manage/export',
    method: 'get',
    params,
  });
}

/**
 * 用户评价 - 获取详情
 * @param params
 */
export function querydetailsById(params) {
  return request({
    url: envInfo.bgApp.gradePath + '/manage/querydetailsById',
    method: 'get',
    params,
  });
}
